import * as React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useI18n } from '@bestyled/primitives'
import { Section, SocialLinks, mediaqueries } from '@slipway/theme-components'

const Footer: React.FC<{}> = () => {
  const { isCompatible } = useI18n()

  const results = useStaticQuery(graphql`
    {
      allSite {
        edges {
          node {
            siteMetadata {
              name
              copyrightOwner
              copyrightLink
              social {
                url
                name
              }
            }
          }
        }
      }
      allPage(
        filter: { menu: { glob: "footer*" } }
        sort: { fields: menu, order: ASC }
      ) {
        edges {
          node {
            id
            menu
            slug
            lang
            title
          }
        }
      }
    }
  `)

  const {
    copyrightOwner,
    copyrightLink,
    social
  } = results.allSite.edges[0].node.siteMetadata

  const navItems = results.allPage.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  return (
    <>
      <FooterGradient />
      <Section narrow>
        <HoritzontalRule />
        <FooterContainer>
          <FooterText>
            <FooterLinkContainer>
              <div>
                © {new Date().getFullYear()}&nbsp;
                <FooterAnchor href={copyrightLink}>
                  {copyrightOwner}
                </FooterAnchor>
              </div>
              {navItems.map((item) => (
                <FooterLink key={item.id} to={item.href}>
                  {item.title}
                </FooterLink>
              ))}
            </FooterLinkContainer>
          </FooterText>
          <FooterSocial>
            <SocialLinks links={social} />
          </FooterSocial>
        </FooterContainer>
      </Section>
    </>
  )
}

export default Footer

const FooterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 80px;
  color: ${(p) => p.theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes[1]}px;

  ${mediaqueries.tablet`
    flex-direction: column;
    padding-bottom: 100px;
  `}

  ${mediaqueries.phablet`
    padding-bottom: 20px;
  `}
`

const FooterLinkContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;

  ${mediaqueries.tablet`
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  `}
`

const HoritzontalRule = styled.div`
  position: relative;
  margin: 40px auto 50px;
  border-bottom: 1px solid ${(p) => p.theme.colors.horizontalRule};

  ${mediaqueries.tablet`
    margin: 40px auto;
  `}

  ${mediaqueries.phablet`
    display: none;
  `}
`

const FooterAnchor = styled.a`
  margin-right: 7px;
  color: #73737d;
`

const FooterSocial = styled.div`
  ${mediaqueries.tablet`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  `}
`

const FooterLink = styled(Link)`
  font-family: ${(p) => p.theme.fonts.serif};
  font-weight: ${(p) => p.theme.fontWeights.menu};
  font-size: ${(p) => p.theme.fontSizes[0]}px;
  margin-right: 7px;
  color: ${(p) => p.theme.colors.grey};
  display: block;
`

const FooterText = styled.div`
  font-family: ${(p) => p.theme.fonts.serif};
  font-weight: ${(p) => p.theme.fontWeights.menu};
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  ${mediaqueries.tablet`
    margin-bottom: 80px;
    width: 100%;
  `}

  ${mediaqueries.phablet`
    margin: 10px auto 10px;
  `}
`

const FooterGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${(p) => p.theme.colors.gradient};
  transition: ${(p) => p.theme.colorModeTransition};
`
