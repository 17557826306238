import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Measure } from '@bestyled/contrib-common'
import { useI18n } from '@bestyled/primitives'
import { mediaqueries } from '@slipway/theme-components'
import { DarkModeToggle, SharePageButton } from './Navigation.IconButtons'
import LanguageLinks from './Navigation.LanguageLinks'

/**
 * The fixed navigation sitting "behind" the mobile version of synctalk.us
 * All the link functionality is taken over by the passed in navigateOut()
 * function that delays and allows us to animate between states.
 *
 * @param navigateOut
 */
const NavigationMobileMenu = ({
  isMobileMenuActive,
  navigateOut
}: {
  isMobileMenuActive: boolean
  navigateOut: (Event, string) => void
}) => {
  const active = isMobileMenuActive || undefined
  const { locale, languages, getLangPathForLocale, isCompatible } = useI18n()

  const results = useStaticQuery(graphql`
    {
      headerMenu: allPage(
        filter: { menu: { glob: "header*" } }
        sort: { fields: menu, order: ASC }
      ) {
        edges {
          node {
            id
            menu
            slug
            menuTitle
            lang
            title
          }
        }
      }
      actionMenu: allPage(
        filter: { menu: { glob: "action*" } }
        sort: { fields: menu, order: ASC }
      ) {
        edges {
          node {
            id
            menu
            slug
            menuTitle
            lang
            title
          }
        }
      }
    }
  `)

  const navItems = results.headerMenu.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  const navActions = results.actionMenu.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  return (
    <NavigationMobileContainer data-active={active}>
      <Measure background="inherit">
        <SocialIconsHeader data-active={active}>
          <DarkModeToggle delay={0} active={active} />
          <SharePageButton delay={0} active={active} />
          <LanguageLinks active={active} handleClick={navigateOut} />
        </SocialIconsHeader>
        <HorizontalRule data-active={active} />
        <MobileLinks>
          {navItems.map((link, index) => (
            <StyledLink
              key={link.id}
              data-active={active}
              index={index}
              to={link.href}
              onClick={(event) => navigateOut(event, link.href)}
              getProps={({ isPartiallyCurrent }) =>
                isPartiallyCurrent ? { 'data-iscurrent': 'true' } : null
              }
            >
              {link.title}
            </StyledLink>
          ))}
        </MobileLinks>
        <MobileActionLinks>
          {navActions.map((link, index) => (
            <StyledLink
              key={link.id}
              data-active={active}
              index={index}
              to={link.href}
              onClick={(event) => navigateOut(event, link.href)}
              getProps={({ isPartiallyCurrent }) =>
                isPartiallyCurrent ? { 'data-iscurrent': 'true' } : null
              }
            >
              {link.title}
            </StyledLink>
          ))}
        </MobileActionLinks>
      </Measure>
    </NavigationMobileContainer>
  )
}
export default NavigationMobileMenu

const NavigationMobileContainer = styled.nav<{ ['data-active']: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  padding: 80px 0;
  background: ${(p) =>
    p['data-active'] ? p.theme.colors.secondarygrey : 'inherit'};
  color: ${(p) => p.theme.colors.grey};

  @media screen and (max-height: 700px) {
    padding: 40px 0;
  }

  ${mediaqueries.desktop_up`
    display: none;
    visibility: hidden;
  `}
`

const SocialIconsHeader = styled.div<{ ['data-active']: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  background: inherit;

  opacity: ${(p) => (p['data-active'] ? 1 : 0)};
  transform: translateY(${(p) => (p['data-active'] ? 0 : -12)}px);
  transition: all 0.5s var(--ease-out-quad) 100ms;

  @media screen and (max-height: 700px) {
    margin-bottom: 40px;
  }
`

const HorizontalRule = styled.hr<{ ['data-active']: boolean }>`
  border: none;
  height: 1px;
  background: ${(p) => p.theme.colors.horizontalRule};
  margin: 0 40px 65px;

  opacity: ${(p) => (p['data-active'] ? 1 : 0)};
  transform: scaleX(${(p) => (p['data-active'] ? 1 : 0.6)});
  transition: transform 0.5s var(--ease-out-quad), opacity 0.4s ease-out;

  @media screen and (max-height: 700px) {
    margin: 0 40px 25px;
  }
`

const MobileLinks = styled.div<{}>`
  display: flex;
  flex-direction: column;
`

const MobileActionLinks = styled.div<{}>`
  display: flex;
  flex-direction: column;

  ${mediaqueries.tablet_up`
    display: none;
    visibility: hidden;
  `}
`

const StyledLink = styled<any>(Link)`
  position: relative;
  font-family: ${(p) => p.theme.fonts.menu};
  text-transform: ${(p) => p.theme.textTransforms.topmenu};
  letter-spacing: 2px;
  display: flex;
  font-weight: ${(p) => p.theme.fontWeights.menu};
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  color: ${(p) => p.theme.colors.primary};
  display: inline-block;
  margin: 0 auto;
  text-align: center;

  opacity: ${(p) => (p['data-active'] ? 1 : 0)};
  transform: translateY(${(p) => (p['data-active'] ? 0 : -10)}px);
  transition: all 0.5s cubic-bezier(0.32, 0.08, 0.24, 1)
    ${(p) => p.index * 28 + 175}ms;

  &[data-iscurrent='true'] {
    color: ${(p) => p.theme.colors.secondary};
  }

  padding: 15px;
  margin-bottom: 5px;

  @media screen and (max-height: 700px) {
    margin-bottom: 0;
  }
`
