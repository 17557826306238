/* eslint-disable no-restricted-globals */
import React, { useCallback, useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { useI18n, useTheme } from '@bestyled/primitives'
import { useMediaSize } from '@bestyled/contrib-common'

import { Section, mediaqueries } from '@slipway/theme-components'

import NavigationMenuDesktop from './Navigation.Menu.Desktop'
import NavigationHamburger from './Navigation.Hamburger'

export const Navigation: React.FC<{
  location: Location
  active: boolean
  setActive: (boolean) => void
}> = ({ active, setActive, location }) => {
  const [showBackArrow, setShowBackArrow] = useState(false)
  const [showPreviousPath, setShowPreviousPath] = useState(false)
  const [linkBackToHome, setLinkBackToHome] = useState(false)

  const theme = useTheme()
  const { Logo, LogoMobile, breakpointLabels, colors } = theme
  const { size: mediaSize } = useMediaSize()
  const isMobile = mediaSize - 1 < breakpointLabels.tablet

  const fill = colors.primary

  const { basePath, basePathI18n } = useI18n()

  const handleEscKeyPress = ({ key }) => {
    if (key === 'Escape') {
      handleOutsideClick()
    }
    if (key === 'g') {
      if (!active && !document.getElementById('CommandLineInput')) {
        handleToggleClick()
      }
    }
  }

  const handleToggleClick = () => {
    setActive(!active)
  }

  const handleOutsideClick = () => {
    if (active) {
      setActive(false)
    }
  }

  const navigateOut = (e, path) => {
    e.preventDefault()
    handleOutsideClick()

    if (path === '#') {
      return
    }

    setTimeout(() => {
      navigate(path)
    }, 250)
  }

  useEffect(() => {
    const prev = localStorage.getItem('previousPath') // set in onRouteUpdate by gatsby

    const isHomepage =
      location.pathname === basePathI18n || location.pathname === basePath

    const previousPathWasHomePageOrSitePage =
      prev === basePath ||
      (prev && /^\/\w\w(?:-\w\w)?\/$/.test(prev)) ||
      (prev && prev.includes('/page/'))

    const isNotPaginated = !location.pathname.includes('/page/')

    setShowBackArrow(
      !isHomepage && previousPathWasHomePageOrSitePage && isNotPaginated
    )

    // for future use

    const urlsThatUseBackButton = ['/scheduling/']

    setLinkBackToHome(
      urlsThatUseBackButton.some(
        (pathname) => location.pathname.indexOf(pathname) >= 0
      )
    )
  }, [location.pathname])

  useEffect(() => {
    window.addEventListener('keydown', handleEscKeyPress)

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleEscKeyPress)
      }
    }
  }, [])

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Section>
        <NavContainer>
          {showBackArrow && (
            <LogoBack onClick={() => window.history.back()} data-a11y="false">
              <BackChevron />
            </LogoBack>
          )}
          {linkBackToHome && !showPreviousPath && (
            <LogoBack onClick={() => navigate(basePathI18n)} data-a11y="false">
              <BackChevron fill={fill} />
            </LogoBack>
          )}
          <LogoContainer
            to={basePathI18n}
            aria-label="Back home"
            data-a11y="false"
          >
            {isMobile ? <LogoMobile fill={fill} /> : <Logo fill={fill} />}
          </LogoContainer>
          <NavigationMenuDesktop active={active} navigateOut={navigateOut} />
          <NavigationHamburger
            active={active}
            onClick={handleToggleClick}
            asCloseButton={false}
          />
        </NavContainer>
      </Section>
    </OutsideClickHandler>
  )
}

export default Navigation

const NavContainer = styled.div`
  background: ${(p) => p.theme.colors.background};
  transition: ${(p) => p.theme.colorModeTransition};
  position: relative;
  padding-top: 30px;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  ${mediaqueries.desktop_medium`
    padding-top: 20px;
  `};

  ${mediaqueries.phablet`
    padding-top: 20px;
  `};
`

const LogoBack = styled.button`
  position: absolute;
  left: -44px;
  top: 107px;

  transition: 0.2s transform var(--ease-out-quad);
  opacity: 0;
  animation: fadein 0.3s linear forwards;

  @keyframes fadein {
    to {
      opacity: 1;
    }
  }

  svg {
    transition: transform 0.25s var(--ease-out-quad);
  }

  &:hover svg,
  &:focus svg {
    transform: translateX(-4px);
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 100%;
    height: 100%;
    border: 2px solid ${(p) => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.desktop_medium`
    top: 57px;
    left: -34px;
  `}

  @media screen and (max-height: 800px) {
    top: 67px;
  }

  ${mediaqueries.tablet`
    display: none;
  `}
`

const LogoContainer = styled(Link)`
  position: relative;
  transition: opacity 0.3s var(--ease-out-quad);
  max-width: 114px;

  &:hover {
    opacity: 0.6;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${(p) => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
`

const BackChevron = ({ fill = 'black' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
      fill={fill}
    />
  </svg>
)
