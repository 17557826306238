/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import { useColorMode, useTheme } from '@bestyled/contrib-use-color-mode'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Swipeable } from 'react-swipeable'
import {
  useMediaSize,
  MeasureProvider,
  useMeasure
} from '@bestyled/contrib-common'

import { I18nProvider } from '@bestyled/primitives'
import {
  GlobalStyles,
  mediaqueries,
  getBreakpointPxFromTheme,
  getWindowDimensions,
  scrollable,
  GridLayoutProvider
} from '@slipway/theme-components'

import NavigationMenuMobile from './Navigation.Menu.Mobile'
import NavigationFooter from './Navigation.Footer'
import NavigationHeader from './Navigation.Header'

import { Languages } from '../types'
import 'react-web-animations-js-wrapper'

// fix dependencies
require.resolve('web-animations-js')

interface LayoutProps {
  location: Location
  basePath: string
  locale: string
  languages: Languages
  translations: any
  padded?: boolean
  footer?: {
    visible?: boolean
  }
}

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
const Layout: React.FC<LayoutProps> = ({
  basePath,
  location,
  locale,
  languages,
  translations,
  children,
  padded
}) => {
  const [active, setActive] = useState(false)
  const theme = useTheme()

  const { size: mediaSize } = useMediaSize()

  const isMobile = mediaSize - 1 < theme.breakpointLabels.tablet

  /**
   * Reset menu whenever we resize from mobile to non-mobile or vice versa
   * as the menu layout is quite different and this avoids left over artifacts
   */
  React.useEffect(() => {
    if (active) {
      setActive(false)
    }
  }, [isMobile])

  React.useEffect(() => {
    window.addEventListener('beforeunload', () => {
      window.localStorage.setItem('previousPath', '')
    })
  }, [])

  return isMobile ? (
    <I18nProvider
      basePath={basePath}
      locale={locale}
      languages={languages}
      translations={translations}
    >
      <MeasureProvider>
        <MobileContainer
          active={active}
          setActive={(value) => setActive(value)}
        >
          <ContentLayout
            location={location}
            active={active}
            setActive={(value) => setActive(value)}
            padded={padded}
          >
            {children}
          </ContentLayout>
        </MobileContainer>
      </MeasureProvider>
    </I18nProvider>
  ) : (
    <I18nProvider
      basePath={basePath}
      locale={locale}
      languages={languages}
      translations={translations}
    >
      <ContentLayout
        location={location}
        active={active}
        setActive={(value) => setActive(value)}
        padded={padded}
      >
        {children}
      </ContentLayout>
    </I18nProvider>
  )
}

export default Layout

const ContentLayout: React.FC<
  Partial<LayoutProps> & {
    active: boolean
    location: Location
    setActive: (boolean) => void
  }
> = ({ active, locale, setActive, children, padded, location }) => {
  const [colorMode] = useColorMode()

  useEffect(() => {
    parent.postMessage({ theme: colorMode }, '*')
  }, [colorMode])

  return (
    <GridLayoutProvider>
      <ContentContainer>
        <GlobalStyles />
        <NavigationHeader
          location={location}
          active={active}
          setActive={setActive}
        />
        {children}
        <NavigationFooter />
      </ContentContainer>
    </GridLayoutProvider>
  )
}

const ContentContainer = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.background};
  transition: ${(p) => p.theme.colorModeTransition};
  min-height: 100vh;
`

const MOBILE_MENU_DURATION = 500

const MobileContainer: React.FC<{
  active: boolean
  setActive: (boolean) => void
}> = ({ active, setActive, children }) => {
  const containerRef = React.useRef(null)

  const [mobileMenuOffset, setMobileMenuOffset] = useState(0)
  const [shouldMaskMobile, setShouldMaskMobile] = useState(false)

  const { height } = useMeasure()
  const mobileMenuActualHeight = height + 160 // + padding of outerNavigationMobileContainer

  /**
   * When we close the mobile nav we have to play a small trick
   * on the user to make it feel like the page transition is keeping state.
   * To do that, we put a Mask over the page content to make it
   * feel smoother and more enjoyable.
   */
  const closeMobileMenu = () => {
    setMobileMenuOffset(0)
    setActive(false)

    // Don't forget to enable scrolling once the nav is closed!
    setTimeout(() => {
      scrollable('enable')
    }, MOBILE_MENU_DURATION)
  }

  /**
   * Since the mobile menu vertically offests the entire page we want to
   * ensure the page is still useable
   */
  const openMobileMenu = () => {
    const { height } = getWindowDimensions()

    // Open the nav at the calculated offset, and then disable scrolling
    setMobileMenuOffset(mobileMenuActualHeight)
    setActive(true)
    scrollable('disable')
  }

  const [colorMode] = useColorMode()

  React.useEffect(() => {
    if (active) {
      openMobileMenu()
    } else {
      scrollable('enable')
    }
  }, [active, colorMode])

  /**
   * we’re hijacking the <Link to="/path" /> functionality here. When a user
   * taps a link we prevent default, close the nav, apply the mask, and _then_
   * finally navigate to the new page.
   */
  const navigateMobileOut = (event, path) => {
    event.preventDefault()
    const { pathname } = window.location
    const isNavigatingToNewPage =
      !pathname.includes(path) || pathname.split('/')[1]

    // Nav closes
    closeMobileMenu()

    // If it's a newly selected page, apply the mask and then wait wait a few ms
    if (isNavigatingToNewPage) {
      setShouldMaskMobile(true)

      setTimeout(() => {
        navigate(path)
      }, MOBILE_MENU_DURATION)
    }
  }

  return (
    <>
      <NavigationMenuMobile
        isMobileMenuActive={active}
        navigateOut={navigateMobileOut}
      />
      <Swipeable onSwipedUp={closeMobileMenu}>
        <StyledInnnerContainer
          isMobileMenuActive={active}
          mobileMenuOffset={mobileMenuOffset}
          onClick={
            active
              ? closeMobileMenu
              : () => {
                  /** noop */
                }
          }
          ref={containerRef}
        >
          {/**
           * This Mask is only applied when navigation to a new page. It's how
           * we’re able to make it feel smooth between mobile navigations
           */}
          <MaskMobile shouldMask={shouldMaskMobile} />

          {/* The rest of the site lives in children */}
          {children}
        </StyledInnnerContainer>
      </Swipeable>
    </>
  )
}

interface SiteContainerProps {
  isMobileMenuActive: boolean
  mask?: boolean
  mobileMenuOffset: number
}

const StyledInnnerContainer = styled.div<SiteContainerProps>`
  position: ${(p) => (p.isMobileMenuActive ? 'fixed' : 'relative')};
  background: ${(p) => p.theme.colors.background};
  transition: ${(p) => p.theme.colorModeTransition};
  min-height: 100vh;
  padding-top: 0px;

  ${mediaqueries.tablet`
    padding-top: ${(p) => (p.padded ? '140px' : '0px')};
    transform: ${(p) =>
      p.isMobileMenuActive ? `translateY(${p.mobileMenuOffset}px)` : 'none'};
    transition: transform ${
      MOBILE_MENU_DURATION + 60
    }ms cubic-bezier(0.52, 0.16, 0.24, 1);
    touch-action: ${(p) => (p.isMobileMenuActive ? 'none' : 'initial')};
    width: 100vw;
  `}
`
// ${p => (p.isMobileMenuActive || p.mask ? 'fixed' : 'relative')};

export function calculateMobileContainerStyles(position: number): {} {
  const { width, height } = getWindowDimensions()
  const breakpoint = getBreakpointPxFromTheme('tablet')

  const styles = {
    opacity: position > height ? 0 : 1,
    transform: `translateY(-${position * 0.11}px)`
  }

  return width > breakpoint || position <= 0 ? {} : styles
}

const MaskMobile = styled.div<{ shouldMask: boolean }>`
  opacity: ${(p) => (p.shouldMask ? 1 : 0)};
  transition: opacity 0.5s linear;
  pointer-events: none;
  background: ${(p) => p.theme.colors.background};

  ${mediaqueries.tablet`
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9; 
  `}
`
